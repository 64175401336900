<template>
  <div id="project-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Projects"
};
</script>

<style lang="scss" scoped>
#project-wrapper {
  color: #1d1d1f;
}
</style>
